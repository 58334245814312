<script>
import Swal from "sweetalert2";
export default {
  props: {
    blocs: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    refreshTable() {
      // console.log(this);
    },
    deleteBloc(blocName, blocUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + blocName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/blocs/delete/" + blocUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  // _this.refreshTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="thead-light">
        <tr>
          <th>Désignation</th>
          <th>Type</th>
          <th>Classe</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in blocs" :key="data.id">
          <td>{{ data.name }}</td>
          <td>{{ data.type }}</td>
          <td>{{ data.classe }}</td>
          <td>
            <router-link
            v-if="$can('update_administration_bloc')"
              :to="{
                name: 'base.infrastructures.blocs.edit',
                params: { uid: data.uuid },
              }"
              class="mr-3 text-primary"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Edit"
              ><i class="mdi mdi-pencil font-size-18"></i
            ></router-link>

            <a
            v-if="$can('delete_administration_bloc')"
              @click="deleteBloc(data.name, data.uuid)"
              class="text-danger"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Delete"
              ><i class="mdi mdi-close font-size-18"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>